import { UseContextReturn } from '~/types/core';

export const registerCommand = {
  execute: async (context: UseContextReturn, userData: Record<string, any>) => {
    const mutation = `
      mutation CreateCustomer($input: CustomerInput!) {
        createCustomer(input: $input) {
          customer {
            id
            firstname
            lastname
            email
            taxvat
            gender
            dob
            custom_attributes {
              attribute_code
              value
            }
          }
        }
      }
    `;

    const mutationVariables = {
      input: {
        firstname: userData.firstname,
        lastname: userData.lastname,
        email: userData.email,
        password: userData.password,
        taxvat: userData.taxvat,
        gender: userData.gender,
        dob: userData.dob, 
        custom_attributes: [
          { attribute_code: "vendedora", value: userData.vendedora },
          { attribute_code: "segmento", value: userData.segmento },
        ]
      },
    };

    try {
      const { data }: any = await context.app.$vsf.$magento.api.customQuery({
        query: mutation,
        queryVariables: mutationVariables,
      });

      return data?.createCustomer?.customer ?? null;
    } catch (error) {
      console.error('Error while registering customer:', error);
      throw new Error('Failed to register customer. Please check the input data.');
    }
  },
};
