

















import { defineComponent } from '@nuxtjs/composition-api';
import { SfCarousel } from '@storefront-ui/vue';
import Vue from 'vue/types/umd';
import BenefitsCard from './BenefitsCard.vue';

interface BenefitsDisplayInstance extends Vue {
  windowWidth: number;
  isMobile: boolean;
  onResize: () => void;
}

export default defineComponent({
  components: {
    BenefitsCard,
    SfCarousel,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    benefits: {
      default: () => [
        {
          title: 'Aproveite o frete grátis',
          subtitle: 'Compras acima de R$ 500,00',
          emphasis: 'Confira as condições*',
          imagePath: 'free-shipping',
        },
        {
          title: 'Pagamento Facilitado',
          subtitle: 'Parcele em até 10x',
          emphasis: '',
          imagePath: 'payment-card',
        },
        {
          title: '3% de desconto no Pix',
          subtitle: 'Pagamento à vista',
          emphasis: '',
          imagePath: 'pix',
        },
        {
          title: 'Entrega em até 48h',
          subtitle: 'Grande São Paulo',
          emphasis: '',
          imagePath: '48h',
        },
      ],
    },
    // eslint-disable-next-line vue/require-prop-types
    settings: {
      default: () => ({
        type: 'carousel',
        rewind: false,
        slidePerPage: false,
        hoverPause: true,
        perView: 4,
        autoplay: 3000,
        gap: 35,

        breakpoints: {
          1700: {
            perView: 3,
            gap: 15,

          },
          1528: {
            perView: 3,

          },
          1274: {
            perView: 3,

          },
          1033: {
            perView: 3,

          },
          1023: {
            perView: 3,

          },
          900: {
            perView: 2,

          },
          768: {
            perView: 2,

          },
          560: {
            perView: 1,

          },
        },
      }),
    },
  },
  data() {
    return {
      windowWidth: 1920,
    };
  },
  computed: {
    isMobile(): boolean {
      return this.windowWidth < 1024;
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize as EventListener);
  },
  methods: {
    onResize(): void {
      (this as unknown as BenefitsDisplayInstance).windowWidth = window.innerWidth;
    },
  },
});
