






























































































import { computed, defineComponent, ref, useContext, watch } from '@nuxtjs/composition-api';
import {
  SfButton,
  SfIcon,
} from '@storefront-ui/vue';
import { useUser } from '~/composables';
import { useUiNotification } from '~/composables/useUiNotification';
import JetProductCardImage from '../../atoms/JetImage/JetProductCardImage.vue';
import SfPrice from '../../molecules/Product/SfPrice.vue';

export default defineComponent({
  name: 'SfProductCard',
  components: {
    SfPrice,
    SfIcon,
    JetProductCardImage,
    SfButton,
  },
  props: {
    keyp: {
      type: [String, Number],
      default: '',
    },
    product: {
      type: Object
    },
    image: {
      type: [Array, Object, String],
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    link: {
      type: [String, Object],
      default: null,
    },
    regularPrice: {
      type: [Number, String],
      default: null,
    },
    specialPrice: {
      type: [Number, String],
      default: null,
    },
    wishlistIcon: {
      type: [String, Array, Boolean],
      default: 'heart',
    },
    isInWishlistIcon: {
      type: [String, Array],
      default: 'heart_fill',
    },
    isInWishlist: {
      type: Boolean,
      default: false,
    },
    showAddToCartButton: {
      type: Boolean,
      default: false,
    },
    isAddedToCart: {
      type: Boolean,
      default: false,
    },
    addToCartDisabled: {
      type: Boolean,
      default: false,
    },
    imageTag: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const qty = ref(1);
    const isAddingToCart = ref(false);
    const flags = computed(() => {
      const baseFlags = props.product?.flagtext
        ? [{
          text: props.product.flagtext,
          position: props.product.flagposition,
          color: props.product.flagcolor,
        }]
        : [];
      if (baseFlags.length) {
        if (props.product.flagtext !== 'Leve + Pague -') {
          baseFlags.push({
            text: 'PROMOÇÃO',
            position: 'inferior',
            color: props.product.flagcolor,
          });
        }
      }
      return baseFlags;
    });
    const { send: sendNotification } = useUiNotification();
    const { app } = useContext();

    watch(qty, (newQty) => {
      emit('update:qty', newQty);
    });
    const { isAuthenticated } = useUser();

    const ariaLabel = () => (props.isInWishlist ? 'Remove from wishlist' : 'Add to wishlist');

    const showAddedToCartBadge = () => !isAddingToCart.value && props.isAddedToCart;

    const currentWishlistIcon = () => (props.isInWishlist ? props.isInWishlistIcon : props.wishlistIcon);

    const wishlistIconClasses = () => {
      const defaultClass = 'sf-button--pure sf-product-card__wishlist-icon';
      return `${defaultClass} ${props.isInWishlist ? 'on-wishlist' : ''}`;
    };

    const toggleIsInWishlist = () => {
      emit('click:wishlist', !props.isInWishlist);
      if (isAuthenticated.value) {
        sendNotification({
          id: Symbol('Product wishlist status toggled'),
          message: props.isInWishlist
            ? app.i18n.t('Product removed from wishlist') as string
            : app.i18n.t('Product added to wishlist') as string,
          persist: false,
          type: 'success',
          icon: 'check',
        });
      }
    };


    const addToCart = () => {
      emit('click:add-to-cart', { product: props.product, quantity: qty.value });
      sendNotification({
        id: Symbol('Product added to cart'),
        message: app.i18n.t('Product added to cart') as string,
        persist: false,
        type: 'success',
        icon: 'check',
      });
      setTimeout(() => {
        isAddingToCart.value = false;
      }, 1000);
    };

    const increaseQty = () => {
      qty.value++;
    };

    const decreaseQty = () => {
      if (qty.value > 1) {
        qty.value--;
      }
    };

    const validateQty = () => {
      if (qty.value < 1) {
        qty.value = 1;
      }
    };

    return {
      qty,
      addToCart,
      increaseQty,
      decreaseQty,
      validateQty,
      wishlistIconClasses,
      ariaLabel,
      showAddedToCartBadge,
      currentWishlistIcon,
      toggleIsInWishlist,
      isAddingToCart,
      flags,

    };
  },
});
