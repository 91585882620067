import { render, staticRenderFns } from "./ProductsCarousel.vue?vue&type=template&id=22616a94&scoped=true&"
import script from "./ProductsCarousel.vue?vue&type=script&lang=ts&"
export * from "./ProductsCarousel.vue?vue&type=script&lang=ts&"
import style0 from "./ProductsCarousel.vue?vue&type=style&index=0&id=22616a94&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22616a94",
  null
  
)

export default component.exports