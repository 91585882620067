import axios from "axios";
import Cookies from "js-cookie";
import { storeToRefs } from "pinia";
import { Cart } from "~/modules/GraphQL/types";
import { useCartStore } from "~/stores/cart";

export function useAbandonedCart() {
  const { userInfo } = storeToRefs(useCartStore());

  const checkAbandonedCart = async (cart: Cart) => {
    try {
      if (userInfo.value) {
        await axios.post(`/acart/save`, {
          cartId: cart.id,
          userName: `${userInfo.value.firstname} ${userInfo.value.lastname}`,
          email: userInfo.value.email,
          cartData: cart,
        });
      }
    } catch (error) {
      console.error("Erro ao salvar carrinho:", error);
    }
  };

  const deleteAbandonedCart = async (cartId: any) => {
    try {
      if (userInfo.value) {
        await axios.delete(`/acart/delete/${cartId}`);
      }
    } catch (error) {
      console.error("Erro ao salvar carrinho:", error);
    }
  };

  const updateCartId = async (cartId: any, newCartId) => {
    try {
      if (userInfo.value) {
        await axios.put(`/acart/update/cartId/${cartId}`, {
          cartId: newCartId,
        });
      }
    } catch (error) {
      console.error("Erro ao salvar carrinho:", error);
    }
  };

  const verifyUtmValues = async () => {
    const utm_keys = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_id"];
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has("utm_source") && urlParams.get("utm_source") === "carrinhoabandonado") {
      utm_keys.forEach((key) => {
        if (urlParams.has(key)) {
          Cookies.set(key, urlParams.get(key), { expires: 1 / 24 });
        }
      });

      await axios.put(`/acart/update/emailConversion/${Cookies.get("utm_term")}`);
    }
  };

  return {
    checkAbandonedCart,
    deleteAbandonedCart,
    verifyUtmValues,
    updateCartId,
  };
}
