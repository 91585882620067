







































import {
  SfCarousel,
  SfSection,
  SfLoader,
  SfButton,
} from '@storefront-ui/vue';

import {
  computed, defineComponent, PropType, ref,
} from '@nuxtjs/composition-api';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import { useAddToCart } from '~/helpers/cart/addToCart';
import { useImage, useProduct } from '~/composables';
import useWishlist from '~/modules/wishlist/composables/useWishlist';
import { useUser } from '~/modules/customer/composables/useUser';
import SvgImage from '~/components/General/SvgImage.vue';
import type { Product } from '~/modules/catalog/product/types';
import SfProductCard from '~/components/JetUi/organisms/Product/SfProductCard.vue';
import { ProductStockStatus } from '~/modules/GraphQL/types';

export default defineComponent({
  name: 'ProductsCarousel',
  components: {
    SfCarousel,
    SfProductCard,
    SfSection,
    SfLoader,
    SfButton,
    SvgImage,
  },
  props: {
    settings: {
      default: () => ({
        type: 'carousel',
        rewind: true,
        perView: 4,
        autoplay: 9000,
        hoverpause: true,
        gap: 30,
        breakpoints: {
          1920: {
            perView: 4,
            gap: 20,
            peek: {
              before: 0,
              after: 50,
            },
          },
          1600: {
            perView: 3,
            gap: 18,
            peek: {
              before: 0,
              after: 50,
            },
          },
          1400: {
            perView: 3,
            gap: 16,
            peek: {
              before: 0,
              after: 50,
            },
          },
          1200: {
            perView: 3,
            gap: 15,
            peek: {
              before: 0,
              after: 50,
            },
          },
          992: {
            perView: 2,
            gap: 12,
            peek: {
              before: 0,
              after: 50,
            },
          },
          768: {
            perView: 2,
            gap: 10,
            peek: {
              before: 0,
              after: 50,
            },
          },
          576: {
            perView: 1,
            gap: 8,
            peek: {
              before: 0,
              after: 50,
            },
          },
          480: {
            perView: 1,
            gap: 5,
            peek: {
              before: 0,
              after: 15,
            },
          },
        },
      }),
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    products: {
      type: Array as PropType<Product[]>,
      required: false,
      default: () => [],
    },
    loading: Boolean,
  },
  setup(props) {
    const qty = ref(1);
    const { isAuthenticated } = useUser();
    const { isInWishlist, addOrRemoveItem } = useWishlist();
    const { addItemToCart, isInCart } = useAddToCart();
    const { getProductPath } = useProduct();

    const mappedProducts = computed(() => props.products.map((product) => ({
      // @ts-ignore
      ...product,
      isInWishlist: isInWishlist({ product }),
    })));

    const addItemToWishlist = async (product) => {
      await addOrRemoveItem({ product });
    };

    const { getMagentoImage, imageSizes } = useImage();

    const handleQtyUpdate = (newQty: number) => {
      qty.value = newQty;
    };

    const isInStock = (product) => {
      return product.stock_status === ProductStockStatus.InStock;
    }

    return {
      addItemToCart,
      addItemToWishlist,
      isAuthenticated,
      isInCart,
      isInWishlist,
      mappedProducts,
      productGetters,
      getMagentoImage,
      imageSizes,
      getProductPath,
      handleQtyUpdate,
      qty,
      isInStock,
    };
  },

});
